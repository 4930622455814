.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    display: inline-block;
  }
  box-sizing: border-box;
  width: 135px;
  height: 32px;
  border-radius: 6px;
  text-align: center;
  text-justify: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;

  &.balance {
    color: #00b828;
    border: 2px solid #00b828;
  }
  &.fault {
    color: #c20000;
    border: 2px solid #c20000;
  }
  &.disable {
    color: #dddddd;
    border: 2px solid #dddddd;
  }
  &.small {
    @media screen and (max-width: 600px) {
      width: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 140px;
  }
}
