.widget {
  box-sizing: border-box;
  width: 200.25px;
  height: 90px;
  margin-bottom: 20px;
  margin-right: 20px;

  border-radius: 8px;
  padding: 18px;
  display: inline-block;
  > h4 {
    padding: 0px;
    margin: 0 0 12px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  > h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &.liveSessions-widget {
    box-shadow: 0px 2px 8px rgba(192, 161, 216, 0.6);
    background: #f9f1ff;
    color: #130056;

    // &:hover {
    //   background: darken($color: #f9f1ff, $amount: 5%);
    // }
  }

  &.videoSilver-widget {
    background: #fff2e9;
    box-shadow: 0px 2px 8px rgba(191, 163, 143, 0.6);
    color: #7c1100;
    // &:hover {
    //   background: darken($color: #fff2e9, $amount: 5%);
    // }
  }

  &.magazine-widget {
    background: #f6ffee;
    box-shadow: 0px 2px 8px rgba(174, 204, 147, 0.6);
    color: #0a390b;
    // &:hover {
    //   background: darken($color: #f6ffee, $amount: 5%);
    // }
  }
  &.qcm-widget {
    background: #f1f5ff;
    box-shadow: 0px 2px 8px rgba(150, 172, 227, 0.6);
    color: #000061;
    // &:hover {
    //   background: darken($color: #f1f5ff, $amount: 5%);
    // }
  }
  &.youtube-widget {
    background: #fff1f1;
    box-shadow: 0px 2px 8px rgba(227, 150, 150, 0.6);
    color: #610000;
    // &:hover {
    //   background: darken($color: #f1f5ff, $amount: 5%);
    // }
  }
  &.forum-widget {
    background: #eaf4ff;
    box-shadow: 0px 2px 8px rgba(150, 184, 227, 0.6);
    color: #004080;
    // &:hover {
    //   background: darken($color: #f1f5ff, $amount: 5%);
    // }
  }
}
